import { User } from "model/user";

const key = "eab701ba-3712-41a7-bcae-b8f765d1a2c0";

const load = new Promise<void>((resolve, reject) => {
	(window as any).zESettings = {
		webWidget: {
			chat: {
				suppress: true,
			},
		},
	};
	const script = document.createElement("script");
	script.id = "ze-snippet";
	script.type = "text/javascript";
	script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + key;
	script.addEventListener("load", () => resolve());
	script.addEventListener("error", () => reject());
	document.head.appendChild(script);
});

const zE = (...params: any) => {
	load.then(() => {
		const zE = (window as any).zE;
		zE(...params);
	});
}

export const toggle = (user?: User | null) => {
	const suppress = !user || !user.permissions.chat;
	zE("webWidget", "updateSettings", {
		webWidget: {
			chat: {
				suppress,
			},
			position: {
				horizontal: "left",
				vertical: "bottom" 
			}
		}
	});
	if (suppress) {
		zE("webWidget", "close");
	}
	if (user && !suppress) {
		zE("webWidget", "identify", {
			name: user.name,
			email: user.email,
			organization: "Client #" + user.clientId,
		});
	}
}
