import * as urls from "./urls";
import * as credentials from "shared/credentials";
import { UserPartial } from "model/user";
import { UserAuth } from "model/user-auth";
import { QuotaResource } from "./resource/quota";
import { LeadResource, NewLeadResource } from "./resource/lead";
import { Lead, NewLead } from "model/lead";
import { LeadImportResource } from "./resource/lead-import";
import { LeadNote, NewLeadNote } from "model/lead-note";
import { LeadNoteResource, NewLeadNoteResource } from "./resource/lead-note";
import { LeadTag, NewLeadTag } from "model/lead-tag";
import { LeadTagResource, NewLeadTagResource } from "./resource/lead-tag";
import { LeadPhone, NewLeadPhone } from "model/lead-phone";
import { LeadPhoneResource, NewLeadPhoneResource } from "./resource/lead-phone";
import { NewLeadPipeline } from "model/lead-pipeline";
import { LeadPipelineResource, NewLeadPipelineResource } from "./resource/lead-pipeline";
import { SavedSearch, NewSavedSearch } from "model/saved-search";
import { SavedListing, NewSavedListing } from "model/saved-listing";
import { NewSavedListingResource, SavedListingResource } from "./resource/saved-listing";
import { NewSavedSearchResource, SavedSearchResource } from "./resource/saved-search";
import { MarketReportSubscriptionResource, NewMarketReportSubscriptionResource } from "./resource/market-report-subscription";
import { Campaign, NewCampaign } from "model/campaign";
import { CampaignResource, NewCampaignResource } from "./resource/campaign";
import { CampaignMessage, NewCampaignMessage } from "model/campaign-message";
import { CampaignRoute, NewCampaignRoute } from "model/campaign-route";
import { CampaignMessageResource, NewCampaignMessageResource } from "./resource/campaign-message";
import { CampaignCampaignMessage, NewCampaignCampaignMessage } from "model/campaign-campaign-message";
import { CampaignCampaignMessageResource, NewCampaignCampaignMessageResource } from "./resource/campaign-campaign-message";
import { CampaignSubscription, NewCampaignSubscription } from "model/campaign-subscription";
import { CampaignSubscriptionResource, NewCampaignSubscriptionResource } from "./resource/campaign-subscription";
import { CampaignEmailResource } from "./resource/campaign-email";
import { LeadAnniversary, NewLeadAnniversary } from "model/lead-anniversary";
import { LeadAnniversaryResource, NewLeadAnniversaryResource } from "./resource/lead-anniversary";
import { SavedSearchResultTotalResource } from "./resource/saved-search-result-total";
import { Task, NewTask } from "model/task";
import { NewTaskResource, TaskResource } from "./resource/task";
import { IntegrationPartial } from "model/integration";
import { IntegrationResource } from "./resource/integration";
import { CampaignStatResource } from "./resource/campaign-stat";
import { CampaignSubscriptionStatResource } from "./resource/campaign-subscription-stat";
import { CampaignRouteResource, NewCampaignRouteResource } from "./resource/campaign-route";
import { CampaignCampaignMessageStatResource } from "./resource/campaign-campaign-message-stat";
import { CampaignCampaignMessageSendEmailResource } from "./resource/campaign-campaign-message-send-email";
import { CampaignCampaignMessageSendEmail } from "model/campaign-campaign-message-send-email";
import { CampaignsSettingsResource } from "./resource/campaigns-settings";
import { CampaignsSettings } from "model/campaigns-settings";
import { Agent, NewAgent } from "model/agent";
import { AgentResource, NewAgentResource } from "./resource/agent";
import { AgentBoard, NewAgentBoard } from "model/agent-board";
import { AgentBoardResource, NewAgentBoardResource } from "./resource/agent-board";
import { NewOffice, Office } from "model/office";
import { NewOfficeResource, OfficeResource } from "./resource/office";
import { OfficeBoard, NewOfficeBoard } from "model/office-board";
import { NewOfficeBoardResource, OfficeBoardResource } from "./resource/office-board";
import { CampaignMessageType } from "type/campaign-message";
import { PageResource } from "./resource/page";
import { PagePartial } from "model/page";
import { MarketResource, NewMarketResource } from "./resource/market";
import { MarketListingResource } from "./resource/market-listing";
import { MarketReportResource } from "./resource/market-report";
import { ResellerResource } from "./resource/reseller";
import { ClientResource } from "./resource/client";
import { EmailSentResource } from "./resource/email-sent";
import { EmailClickResource } from "./resource/email-click";
import { LeadLastActivityResource } from "./resource/lead-last-activity";
import { BoardResource } from "./resource/boards";
import { CityResource } from "./resource/city";
import { NeighborhoodResource } from "./resource/neighborhood";
import { AggregateStatsResource } from "./resource/aggregate-stats";
import { IntegrationCalendarResource } from "./resource/integration-calendar";
import { SiteLinkResource } from "./resource/site-link";
import { LanguageResource } from "./resource/language";
import { LeadActivityStatsResource } from "./resource/lead-activity-stats";
import { LeadActivitiesResource } from "./resource/lead-activities";
import { UserResource } from "./resource/user";
import { UserAuthResource } from "./resource/user-auth";
import { NewRoundRobinRoute } from "model/round-robin-route";
import { RoundRobinRouteResource, NewRoundRobinRouteResource } from "./resource/round-robin-route";
import { PostalCodeResource } from "./resource/postal-code";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import { GeographicRouteResource, NewGeographicRouteResource } from "./resource/geographic-route";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { Market, NewMarket } from "model/market";
import { MarketReport } from "model/market-report";
import { MarketListing } from "model/market-listing";
import { ListingResource } from "./resource/listing";
import { MarketSettingsResource } from "./resource/market-settings";
import { MarketSettings } from "model/market-settings";


export const getUser = () => {
    return requestLegacy<UserResource>({
        url: urls.user(),
        method: "GET",
    });
}

export const saveUser = (user: UserPartial) => {
    const body = {
        ...user,
        ...(user.kestrelVersion && {kestrelVersion: user.kestrelVersion.id}),
        ...(user.websitePlatform && {websitePlatform: user.websitePlatform.id}),
        ...(user.listingDetailSidebarPosition && {listingDetailSidebarPosition: user.listingDetailSidebarPosition.id}),
        ...(user.settingListingDetailPageStyle && {settingListingDetailPageStyle: user.settingListingDetailPageStyle.id}),
        ...(user.leadCaptureType && {leadCaptureType: user.leadCaptureType.id}),
        ...(user.searchPagePromptType && {searchPagePromptType: user.searchPagePromptType.id}),
        ...(user.searchResultsPromptType && {searchResultsPromptType: user.searchResultsPromptType.id}),
        ...(user.searchDetailPromptType && {searchDetailPromptType: user.searchDetailPromptType.id}),
        ...(user.mapPosition && {mapPosition: user.mapPosition.id}),
		...(user.agentSortOrder && {agentSortOrder: user.agentSortOrder.id}),
		...(user.textMessageCampaignStatus && {textMessageCampaignStatus: user.textMessageCampaignStatus.id}),
    };
    return requestLegacy({
        url: urls.user(),
        method: "PUT",
        body
    });
}

export const saveUserAuth = (userAuth: UserAuth) => {
    const body = {
        ...userAuth
    }
    return requestLegacy<UserAuthResource>({
        url: urls.userAuth(),
        method: "PUT",
        body
    })
}

export const getAdminMode = () => {
    const url = urls.adminMode();
    return new Promise<boolean>((resolve, reject) => {
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
        .then((response) => {
            response.json().then((json) => {
                resolve(json);
            });
        })
        .catch((error) => {
            reject(error);
        })
        ;
    });
}

export const getResellerById = (id: number) => {
    return requestLegacy<ResellerResource>({
        url: urls.reseller(id),
        method: "GET",
    });
}

export const getClient = (id: number) => {
    return requestLegacy<ClientResource>({
        url: urls.client(id),
        method: "GET",
    });
}

export const getQuota = () => {
    return requestLegacy<QuotaResource>({
        url: urls.quota(),
        method: "GET",
    });
}

export const getLeads = () => {
    return requestLegacy<LeadResource[]>({
        url: urls.leads(),
        method: "GET",
    });
}

export const createLead = (lead: NewLead) => {
	const body: NewLeadResource = {
		name: lead.name,
		email: lead.email,
		email2: lead.email2,
		phone: lead.phone,
		address: lead.address,
		city: lead.city,
		state: lead.state,
		zip: lead.zip,
		status: lead.status && lead.status.id,
		note: lead.note,
		agentId: lead.agent ? lead.agent.id : undefined,
	};
	return request<LeadResource, NewLeadResource>({
		url: urls.leads(),
		method: "POST",
		body,
	});
}

export const updateLead = (lead: Lead) => {
	const body: LeadResource = {
		id: lead.id,
		name: lead.name,
		email: lead.email,
		email2: lead.email2,
		phone: lead.phone,
		address: lead.address,
		city: lead.city,
		state: lead.state,
		zip: lead.zip,
		status: lead.status && lead.status.id,
		note: lead.note,
		agentId: lead.agent ? lead.agent.id : undefined,
	};
	return request<LeadResource, LeadResource>({
		url: urls.lead(lead.id),
		method: "PUT",
		body,
	});
}

export const getSavedListings = (leadId: number) => {
    return requestLegacy<SavedListingResource[]>({
        url: urls.savedListings(leadId),
        method: "GET",
    });
}

export const createSavedListing = (savedListing: NewSavedListing) => {
	const body: NewSavedListingResource = {
		leadId: savedListing.lead && savedListing.lead.id,
		rating: savedListing.rating,
		comments: savedListing.comments,
		deleted: savedListing.deleted ? savedListing.deleted : false,
		// @ts-ignore
		listing: savedListing.listing,
	};

	return request<SavedListingResource, NewSavedListingResource>({
		url: urls.savedListings(),
		method: "POST",
		body,
	});
}

export const updateSavedListing = (savedListing: SavedListing) => {
	let listing: ListingResource | undefined = savedListing.listing ? {
		id: savedListing.listing.id,
		listingNumber: savedListing.listing.listingNumber,
		boardId: savedListing.listing.boardId,
		propertyType: savedListing.listing.propertyType?.id || "",
		address: savedListing.listing.address.join(" "),
	} : undefined;
	const body: SavedListingResource = {
		id: savedListing.id,
		leadId: savedListing.lead && savedListing.lead.id,
		deleted: savedListing.deleted ? savedListing.deleted : false,
		rating: savedListing.rating ? savedListing.rating : undefined,
		comments: savedListing.comments ? savedListing.comments : undefined,
		createdOn: savedListing.createdOn ? savedListing.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : undefined,
		modifiedOn: savedListing.modifiedOn ? savedListing.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : undefined,
		listing: listing ? listing : undefined,
	};
	return request<SavedListingResource, SavedListingResource>({
		url: urls.savedListing(savedListing.id),
		method: "PUT",
		body,
	});
}

export const getSavedSearches = () => {
    return requestLegacy<SavedSearchResource[]>({
        url: urls.savedSearches(),
        method: "GET",
    });
}

export const createSavedSearch = (savedSearch: NewSavedSearch) => {
	const body: NewSavedSearchResource = {
		leadId: savedSearch.leadId,
		name: savedSearch.name,
		recieveEmails: savedSearch.recieveEmails,
		comments: savedSearch.comments,
		inactive: savedSearch.inactive,
		criteria: savedSearch.criteria,
	};
	return request<SavedSearchResource, NewSavedSearchResource>({
		url: urls.savedSearches(),
		method: "POST",
		body,
	});
}

export const updateSavedSearch = (savedSearch: SavedSearch) => {
	const body: SavedSearchResource = {
		id: savedSearch.id,
		leadId: savedSearch.leadId,
		name: savedSearch.name,
		recieveEmails: savedSearch.recieveEmails,
		comments: savedSearch.comments,
		inactive: savedSearch.inactive,
		createdOn: savedSearch.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		modifiedOn: savedSearch.modifiedOn ? savedSearch.modifiedOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : undefined, 
		criteria: savedSearch.criteria,
		resultsUrl: savedSearch.resultsUrl,
	};
	return request<SavedSearchResource, SavedSearchResource>({
		url: urls.savedSearch(savedSearch.id),
		method: "PUT",
		body,
	});
}

export const getSavedSearchResultTotal = (savedSearchId: number) => {
    return requestLegacy<SavedSearchResultTotalResource[]>({
        url: urls.savedSearchResultTotal(savedSearchId),
        method: "GET",
    });
}

export const getEmailSents = (leadId: number) => {
    return requestLegacy<EmailSentResource[]>({
        url: urls.emailSents(leadId),
        method: "GET",
    });
}

export const getEmailClicks = (leadId: number) => {
    return requestLegacy<EmailClickResource[]>({
        url: urls.emailClicks(leadId),
        method: "GET",
    });
}

export const getLeadPhones = () => {
    return requestLegacy<LeadPhoneResource[]>({
        url: urls.leadPhones(),
        method: "GET"
    });
}

export const createLeadPhone = (leadPhone: NewLeadPhone) => {
	const body: NewLeadPhoneResource = {
		leadId: leadPhone.leadId,
		typeId: leadPhone.type && leadPhone.type.id,
		value: leadPhone.value,
		deleted: leadPhone.deleted
	};
	return request<LeadPhoneResource, NewLeadPhoneResource>({
		url: urls.leadPhones(),
		method: "POST",
		body
	});
}

export const updateLeadPhone = (leadPhone: LeadPhone) => {
	const body: LeadPhoneResource = {
		id: leadPhone.id,
		leadId: leadPhone.leadId,
		typeId: leadPhone.type && leadPhone.type.id,
		value: leadPhone.value,
		deleted: leadPhone.deleted,
		createdOn: leadPhone.createdOn.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: leadPhone.updatedOn.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
	};
	return request<LeadPhoneResource, LeadPhoneResource>({
		url: urls.leadPhone(leadPhone.id),
		method: "PUT",
		body
	});
}

export const getLeadPipelines = () => {
    return requestLegacy<LeadPipelineResource[]>({
        url: urls.leadPipelines(),
        method: "GET"
    })
}

export const createLeadPipeline = (leadPipeline: NewLeadPipeline) => {
    const body: NewLeadPipelineResource = {
        leadId: leadPipeline.leadId,
        typeId: leadPipeline.type && leadPipeline.type.id,
    }
    return request<LeadPipelineResource, NewLeadPipelineResource>({
        url: urls.leadPipelines(),
        method: "POST",
        body
    })
}

export const getLeadAnniversaries = () => {
    return requestLegacy<LeadAnniversaryResource[]>({
        url: urls.leadAnniversaries(),
        method: "GET"
    });
}

export const createLeadAnniversary = (leadAnniversary: NewLeadAnniversary) => {
	const body: NewLeadAnniversaryResource = {
		leadId: leadAnniversary.lead && leadAnniversary.lead.id,
		typeId: leadAnniversary.type && leadAnniversary.type.id,
		startDate: leadAnniversary.startDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		deleted: leadAnniversary.deleted ? leadAnniversary.deleted : false,
	};
	return request<LeadAnniversaryResource, NewLeadAnniversaryResource>({
		url: urls.leadAnniversaries(),
		method: "POST",
		body
	});
};

export const updateLeadAnniversary = (leadAnniversary: LeadAnniversary) => {
	const body: LeadAnniversaryResource = {
		id: leadAnniversary.id,
		leadId: leadAnniversary.lead && leadAnniversary.lead.id,
		typeId: leadAnniversary.type && leadAnniversary.type.id,
		startDate: leadAnniversary.startDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		createdOn: leadAnniversary.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: leadAnniversary.updatedOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		deleted: leadAnniversary.deleted ? leadAnniversary.deleted : false,
	};
	return request<LeadAnniversaryResource, LeadAnniversaryResource>({
		url: urls.leadAnniversary(leadAnniversary.id),
		method: "PUT",
		body,
	});
}

export const getLeadNotes = () => {
    return requestLegacy<LeadNoteResource[]>({
        url: urls.leadNotes(),
        method: "GET"
    });
}

export const saveLeadImport = (leadImportResources: LeadImportResource[]) => {
    const body = leadImportResources;
    return requestLegacy<LeadImportResource[]>({
        url: urls.leadImports(),
        method: "POST",
        body,
    });
}

export const createLeadNote = (leadNote: NewLeadNote) => {
	const text = (leadNote.text) ? leadNote.text : "";
	const body: NewLeadNoteResource = {
        leadId: leadNote.leadId,
        typeId: leadNote.type && leadNote.type.id,
        text,
        deleted: leadNote.deleted
    };
	return request<LeadNoteResource, NewLeadNoteResource>({
		url: urls.leadNotes(),
		method: "POST",
		body,
	});
}

export const updateLeadNote = (leadNote: LeadNote) => {
	const text = (leadNote.text) ? leadNote.text : "";
	const body: LeadNoteResource = {
		...leadNote,
        leadId: leadNote.leadId,
        typeId: leadNote.type && leadNote.type.id,
        text,
        deleted: leadNote.deleted,
		updatedOn: leadNote.updatedOn.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		createdOn: leadNote.createdOn.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
	return request<LeadNoteResource, LeadNoteResource>({
		url: urls.leadNote(leadNote.id),
		method: "PUT",
		body,
	});
};

export const getLeadTags = () => {
    return requestLegacy<LeadTagResource[]>({
        url: urls.leadTags(),
        method: "GET"
    });
}

export const createLeadTag = (leadTag: NewLeadTag) => {
    const body: NewLeadTagResource = {
		leadId: leadTag.leadId,
		text: leadTag.text,
	};
    return request<LeadTagResource, NewLeadTagResource>({
        url: urls.leadTags(),
        method: "POST",
        body,
    });
}

export const deleteLeadTag = (leadTag: LeadTag) => {
    return requestLegacy<LeadTagResource>({
        url: urls.leadTag(leadTag.id || 0),
        method: "DELETE",
    });
}

export const getLeadActivities = (leadId?: number) => {
    return requestLegacy<LeadActivitiesResource[]>({
        url: urls.leadActivities(leadId),
        method: "GET",
    });
}

export const getLeadActivityStats = () => {
    return requestLegacy<LeadActivityStatsResource[]>({
        url: urls.leadActivityStats(),
        method: "GET",
    });
}

export const getLeadLastActivities = () => {
    return requestLegacy<LeadLastActivityResource[]>({
        url: urls.leadLastActivities(),
        method: "GET",
    });
}

export const getGeographicRoutes = () => {
	return request<GeographicRouteResource[]>({
		url: urls.geographicRoutes(),
		method: "GET",
	});
}

export const createGeographicRoute = (geographicRoute: NewGeographicRoute) => {
	const body: NewGeographicRouteResource = {
		agentId: geographicRoute.agent.id,
		postalCode: geographicRoute.postalCode,
	};
	return request<GeographicRouteResource, NewGeographicRouteResource>({
		url: urls.geographicRoutes(),
		method: "POST",
		body,
	});
};

export const deleteGeographicRoute = (geographicRoute: GeographicRoute) => {
		return request<GeographicRouteResource>({
			url: urls.geographicRoute(geographicRoute.id),
			method: "DELETE",
		});
};

export const getRoundRobinRoutes = () => {
	return request<RoundRobinRouteResource[]>({
		url: urls.roundRobinRoutes(),
		method: "GET",
	});
}

export const updateRoundRobinRoutes = (roundRobinRoutes: NewRoundRobinRoute[]) => {
	const body: NewRoundRobinRouteResource[] = [];
	roundRobinRoutes.forEach(roundRobinRoute => {
		body.push({
			agentId: roundRobinRoute.agent.id,
		});
	})
	return request<RoundRobinRouteResource[], NewRoundRobinRouteResource[]>({
		url: urls.roundRobinRoutes(),
		method: "PUT",
		body,
	});
}

export const getPostalCodes = () => {
	return request<PostalCodeResource[]>({
		url: urls.postalCodesRoutes(),
		method: "GET",
	});
}


export const createCampaign = (campaign: NewCampaign) => {
	const body: NewCampaignResource = {
		name: campaign.name,
		description: campaign.description,
		campaignType: campaign.campaignType && campaign.campaignType.id,
		marketId: campaign.marketId,
		emailBrandingYn: campaign.emailBrandingYn,
		emailMarketingTypeId: campaign.emailMarketingType.id,
		inactiveYn: campaign.inactiveYn,
	};
	return request<CampaignResource, NewCampaignResource>({
		url: urls.campaigns(),
		method: "POST",
		body,
	});
};

export const getCampaigns = () => {
    return requestLegacy<CampaignResource[]>({
        url: urls.campaigns(),
        method: "GET",
    });
}

export const updateCampaign = (campaign: Campaign) => {
	const body: CampaignResource = {
		id: campaign.id,
		name: campaign.name,
		status: campaign.status && campaign.status.id,
		description: campaign.description,
		campaignType: campaign.campaignType && campaign.campaignType.id,
		marketId: campaign.marketId,
		emailBrandingYn: campaign.emailBrandingYn,
		emailMarketingTypeId: campaign.emailMarketingType.id,
		inactiveYn: campaign.inactiveYn,
		dateCreated: campaign.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSS"),
	};
	return request<CampaignResource, CampaignResource>({
		url: urls.campaign(campaign.id),
		method: "PUT",
		body,
	});
};

export const getCampaignMessages = () => {
    return requestLegacy<CampaignMessageResource[]>({
        url: urls.campaignMessages(),
        method: "GET",
    });
}

export const resendTextingOtpVerification = () => {
	return request<string>({
		url: urls.campaignTextingResendOtp(),
		method: "POST"
	});
}

export const createCampaignMessage = (campaignMessage: NewCampaignMessage) => {
	const attachments = (campaignMessage.attachments) ? campaignMessage.attachments : [];
	const body: NewCampaignMessageResource = {
		name: campaignMessage.name,
		subject: campaignMessage.subject,
		htmlContent: campaignMessage.htmlContent,
		jsonContent: campaignMessage.jsonContent,
		messageType: campaignMessage.messageType ? campaignMessage.messageType.id : CampaignMessageType.EMAIL.id,
		attachments,
		timeToSendNumber: campaignMessage.timeToSendNumber,
		timeToSendUnit: campaignMessage.timeToSendUnit && campaignMessage.timeToSendUnit.id,
		status: campaignMessage.status && campaignMessage.status.id,
		isInactiveYn: campaignMessage.isInactiveYn,
		ownedByReseller: campaignMessage.ownedByReseller,
	};
	return request<CampaignMessageResource, NewCampaignMessageResource>({
		url: urls.campaignMessages(),
		method: "POST",
		body,
	});
}

export const updateCampaignMessage = (campaignMessage: CampaignMessage) => {
	const body: CampaignMessageResource = {
		id: campaignMessage.id,
		name: campaignMessage.name,
		subject: campaignMessage.subject,
		htmlContent: campaignMessage.htmlContent,
		jsonContent: campaignMessage.jsonContent,
		messageType: campaignMessage.messageType ? campaignMessage.messageType.id : CampaignMessageType.EMAIL.id,
		attachments: campaignMessage.attachments,
		timeToSendNumber: campaignMessage.timeToSendNumber,
		timeToSendUnit: campaignMessage.timeToSendUnit && campaignMessage.timeToSendUnit.id,
		status: campaignMessage.status && campaignMessage.status.id,
		isInactiveYn: campaignMessage.isInactiveYn,
		ownedByReseller: false,
		dateCreated: ""
	};
	return request<CampaignMessageResource, CampaignMessageResource>({
		url: urls.campaignMessage(campaignMessage.id),
		method: "PUT",
		body,
	});
};	

export const deleteCampaignMessage = (campaignMessage: CampaignMessage) => {
    return requestLegacy<CampaignMessageResource>({
        url: urls.campaignMessage(campaignMessage.id || 0),
        method: "PUT",
    });
}

export const getCampaignCampaignMessages = () => {
    return requestLegacy<CampaignCampaignMessageResource[]>({
        url: urls.campaignCampaignMessages(),
        method: "GET",
    });
}

export const createCampaignCampaignMessage = (campaignCampaignMessage: NewCampaignCampaignMessage) => {
	const body: NewCampaignCampaignMessageResource = {
		campaignId: campaignCampaignMessage.campaignId,
		campaignMessageId: campaignCampaignMessage.campaignMessageId,
		messageOrder: campaignCampaignMessage.messageOrder,
	};
	return request<CampaignCampaignMessageResource, NewCampaignCampaignMessageResource>({
		url: urls.campaignCampaignMessages(),
		method: "POST",
		body,
	});
};

export const updateCampaignCampaignMessage = (campaignCampaignMessage: CampaignCampaignMessage) => {
	const body: CampaignCampaignMessageResource = {
		id: campaignCampaignMessage.id,
		campaignId: campaignCampaignMessage.campaignId,
		campaignMessageId: campaignCampaignMessage.campaignMessageId,
		messageOrder: campaignCampaignMessage.messageOrder,
		dateCreated: campaignCampaignMessage.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	return request<CampaignCampaignMessageResource, CampaignCampaignMessageResource>({
		url: urls.campaignCampaignMessage(campaignCampaignMessage.id),
		method: "PUT",
		body,
	});
};

export const updateCampaignCampaignMessages = (campaignCampaignMessages: CampaignCampaignMessage[]) => {
    const body: CampaignCampaignMessageResource[] = campaignCampaignMessages.map((campaignCampaignMessage) => (
        {
            id: campaignCampaignMessage.id,
            campaignId: campaignCampaignMessage.campaignId,
            campaignMessageId: campaignCampaignMessage.campaignMessageId,
            messageOrder: campaignCampaignMessage.messageOrder,
			dateCreated: campaignCampaignMessage.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        }
    ));
    return request<CampaignCampaignMessageResource[], CampaignCampaignMessageResource[]>({
        url: urls.campaignCampaignMessages(),
        method: "PUT",
        body,
    });
}

export const sendCampaignCampagaignMessageEmailTest = (campaignCampaignMessageSendEmail: CampaignCampaignMessageSendEmail) => {
    const body: CampaignCampaignMessageSendEmailResource = {
        campaignCampaignMessageId: campaignCampaignMessageSendEmail.campaignCampaignMessage.id,
        leadId: campaignCampaignMessageSendEmail.lead.id,
    }
    return requestLegacy<CampaignCampaignMessageSendEmailResource>({
        url: urls.campaignCampaignMessageSendTestEmail(),
        method: "POST",
        body,
    });
}

export const getCampaignSubscriptions = () => {
    return requestLegacy<CampaignSubscriptionResource[]>({
        url: urls.campaignSubscriptions(),
        method: "GET",
    });
};

export const createCampaignSubscription = (campaignSubscription: NewCampaignSubscription) => {
	const body: NewCampaignSubscriptionResource = {
		campaignId: campaignSubscription.campaign.id,
		leadId: campaignSubscription.lead.id,
		status: campaignSubscription.status.id,
	};
	return request<CampaignSubscriptionResource, NewCampaignSubscriptionResource>({
		url: urls.campaignSubscriptions(),
		method: "POST",
		body,
	});
};

export const updateCampaignSubscription = (campaignSubscription: CampaignSubscription) => {
	const body: CampaignSubscriptionResource = {
		id: campaignSubscription.id,
		campaignId: campaignSubscription.campaign.id,
		leadId: campaignSubscription.lead.id,
		status: campaignSubscription.status.id,
		createdOn: campaignSubscription.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	return request<CampaignSubscriptionResource, CampaignSubscriptionResource>({
		url: urls.campaignSubscription(campaignSubscription.id),
		method: "PUT",
		body,
	});
};

export const getCampaignEmails = (leadId?: number, campaignId?: number, blast?: boolean) => {
    return requestLegacy<CampaignEmailResource[]>({
        url: urls.campaignEmails(leadId, campaignId, blast),
        method: "GET",
    });
}

export const getCampaignStats = () => {
        return requestLegacy<CampaignStatResource[]>({
            url: urls.campaignStats(),
            method: "GET",
        });
}

export const getCampaignSubscriptionStats = () => {
    return requestLegacy<CampaignSubscriptionStatResource[]>({
        url: urls.campaignSubscriptionStats(),
        method: "GET",
    });
}

export const getCampaignCampaignMessageStats = () => {
    return requestLegacy<CampaignCampaignMessageStatResource[]>({
        url: urls.campaignCampaignMessageStats(),
        method: "GET",
    });
}

export const getCampaignRoutes = () => {
    return requestLegacy<CampaignRouteResource[]>({
        url: urls.campaignRoutes(),
        method: "GET",
    });
}

export const createCampaignRoute = (campaignRoute: NewCampaignRoute) => {
	const body: NewCampaignRouteResource = {
		campaignId: campaignRoute.campaign.id,
		leadSource: campaignRoute.leadSource,
	};
	return request<CampaignRouteResource, NewCampaignRouteResource>({
		url: urls.campaignRoutes(),
		method: "POST",
		body,
	});
};

export const updateCampaignRoute = (campaignRoute: CampaignRoute) => {
	const body: CampaignRouteResource = {
		id: campaignRoute.id,
		campaignId: campaignRoute.campaign.id,
		leadSource: campaignRoute.leadSource,
	};
	return request<CampaignRouteResource, CampaignRouteResource>({
		url: urls.campaignRoute(campaignRoute.id),
		method: "PUT",
		body,
	});
};

export const deleteCampaignRoute = (campaignRoute: CampaignRoute) => {
    const body: CampaignRouteResource = {
        id: campaignRoute.id,
        campaignId: campaignRoute.campaign.id,
        leadSource: campaignRoute.leadSource,
    };
    return requestLegacy<CampaignRouteResource>({
        url: urls.campaignRoute(campaignRoute.id),
        method: "DELETE",
        body,
    });
}

export const getCampaignsSettings = () => {
    return requestLegacy<CampaignsSettingsResource>({
        url: urls.campaingsSettings(),
        method: "GET",
    });
}

export const updateCampaignsSettings = (campaignsSettings: CampaignsSettings) => {
    const body: CampaignsSettingsResource = {}
    if (campaignsSettings.dynamicContentAddress) {
        body.dynamicContentAddress = campaignsSettings.dynamicContentAddress
    }
    if (campaignsSettings.lead) {
        body.leadId = campaignsSettings.lead.id
    }
    return requestLegacy<CampaignsSettingsResource>({
        url: urls.campaingsSettings(),
        method: "PUT",
        body,
    });
}

export const createMarket = (market: NewMarket) => {
	const body: NewMarketResource = {
		name: market.name,
		description: market.description,
		criteria: market.criteria,
		inactive: market.inactive ? market.inactive : false,
	}
    return request<MarketResource, NewMarketResource>({
        url: urls.markets(),
        method: "POST",
        body,
    });
}

export const updateMarket = (market: Market) => {
	const body: MarketResource = {
		id: market.id,
		name: market.name,
		description: market.description,
		criteria: market.criteria,
		header: market.header,
		footer: market.footer,
		subhead: market.subhead,
		showOnIndex: market.showOnIndex,
		sortOrder: market.sortOrder,
		photoUrl: market.photoUrl,
		inactive: market.inactive ? market.inactive : false
	}
    return requestLegacy<MarketResource>({
        url: urls.market(body.id),
        method: "PUT",
        body,
    });
}

export const getMarkets = () => {
    return requestLegacy<MarketResource[]>({
        url: urls.markets(),
        method: "GET",
    });
}

export const getMarketSettings = () => {
	return requestLegacy<MarketSettingsResource>({
		url: urls.marketSettings(),
		method: "GET",
	});
}

export const updateMarketSettings = (marketSettings: MarketSettings) => {
	const body: MarketSettingsResource = {
		listingCarouselColumns: marketSettings.listingCarouselColumns,
		communitiesLayout: marketSettings.communitiesLayout
	}

	return requestLegacy<MarketSettingsResource>({
		url: urls.marketSettings(),
		method: "PUT",
	body,
	});
}

export const getMarketListings = () => {
	return requestLegacy<MarketListingResource[]>({
		url: urls.marketListings(),
		method: "GET",
	});
}

export const getMarketReports = () => {
    return requestLegacy<MarketReportResource[]>({
        url: urls.marketReports(),
        method: "GET",
    });
}

export const updateMarketReport = (marketReport: MarketReport, marketId: number) => {
	const body: MarketReportResource = {
		id: marketReport.id,
		typeId: marketReport.type.id,
		customUrl: marketReport.customUrl,
		displayInNavigation: marketReport.displayInNavigation,
		emailIntroText: marketReport.emailIntroText,
		webpageIntroText: marketReport.webpageIntroText,
		marketId: marketId,
		inactive: marketReport.inactive,
		limitEmailToFeatured: marketReport.limitEmailToFeatured,
	}
    return request<MarketReportResource, MarketReportResource>({
        url: urls.marketReport(body.id),
        method: "PUT",
        body,
    });
}

export const getMarketReportSubscriptions = () => {
    return requestLegacy<MarketReportSubscriptionResource[]>({
        url: urls.marketReportSubscriptions(),
        method: "GET",
    });
}

export const createMarketReportSubscription = (resource: NewMarketReportSubscription) => {
    const body: NewMarketReportSubscriptionResource = {
		marketReportId: resource.report.id,
		leadId: resource.lead.id,
		inactive: resource.inactive,
	}
    return request<MarketReportSubscriptionResource, NewMarketReportSubscriptionResource>({
        url: urls.marketReportSubscriptions(),
        method: "POST",
        body,
    });
}

export const updateMarketReportSubscription = (resource: MarketReportSubscription) => {
	const body: MarketReportSubscriptionResource = {
		id: resource.id,
		marketReportId: resource.report.id,
		leadId: resource.lead.id,
		inactive: resource.inactive,
		createdOn: resource.createdOn ? resource.createdOn.toISOString() : undefined,
	}
    return request<MarketReportSubscriptionResource, MarketReportSubscriptionResource>({
        url: urls.marketReportSubscription(body.id),
        method: "PUT",
        body,
    });
}

export const updateMarketSubscriptions = (marketId: number, subscriptions: (NewMarketReportSubscription | MarketReportSubscription)[]) => {
	let body: (NewMarketReportSubscriptionResource | MarketReportSubscriptionResource)[] = []; 
	subscriptions.forEach((subscription) => {
		if ("id" in subscription) {
			body.push({
				id: subscription.id,
				marketReportId: subscription.report.id,
				leadId: subscription.lead.id,
				inactive: subscription.inactive
			})
		} else {
			body.push({
				leadId: subscription.lead.id,
				marketReportId: subscription.report.id,
				inactive: subscription.inactive
			})
		}
	})
    return request<MarketReportSubscriptionResource[], (NewMarketReportSubscriptionResource | MarketReportSubscriptionResource)[]>({
        url: urls.marketSubscriptions(marketId),
        method: "PUT",
        body,
	});
}

export const getTasks = () => {
    return requestLegacy<TaskResource[]>({
        url: urls.tasks(),
        method: "GET",
    });
}

export const createTask = (task: NewTask) => {
	const body: NewTaskResource = {
        leadId: task.leadId,
		agentId: task.agentId,
		creatorId: task.creatorId,
        title: task.title,
        description: task.description,
        taskTypeId: task.type && task.type.id,
        taskStatusTypeId: task.status && task.status.id,
        priority: task.priority,
        startOn: task.startOn && task.startOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        endOn: task.endOn && task.endOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
	return request<TaskResource, NewTaskResource>({
		url: urls.tasks(),
		method: "POST",
		body,
	});
}

export const updateTask = (task: Task) => {
	const body: TaskResource = {
		id: task.id,
        leadId: task.leadId,
		agentId: task.agentId,
		creatorId: task.creatorId,
        title: task.title,
        description: task.description,
        taskTypeId: task.type && task.type.id,
        taskStatusTypeId: task.status && task.status.id,
        priority: task.priority,
        startOn: task.startOn && task.startOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        endOn: task.endOn && task.endOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		createdOn: task.createdOn && task.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: task.updatedOn && task.updatedOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
	return request<TaskResource, TaskResource>({
		url: urls.task(task.id),
		method: "PUT",
		body,
	});
}

export const getAgents = () => {
    return requestLegacy<AgentResource[]>({
        url: urls.agents(),
        method: "GET",
    });
}

export const createAgent = (agent: NewAgent) => {
	const body: NewAgentResource = {
        firstName: agent.firstName,
        lastName: agent.lastName,
        email: agent.email,
        officeId: agent.office && agent.office.id,
        agentPageUrl: agent.agentPageUrl,
        lastLoginDate:
            agent.lastLoginDate &&
            agent.lastLoginDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        designation: agent.designation,
        username: agent.username,
        password: agent.password,
        homePhone: agent.homePhone,
        officePhone: agent.officePhone,
        homeFax: agent.homeFax,
        officeFax: agent.officeFax,
        cellPhone: agent.cellPhone,
        staffTypeId: agent.staffType && agent.staffType.id,
        code: agent.code,
        officeAddress: agent.officeAddress,
        officeCity: agent.officeCity,
        officeState: agent.officeState,
        officeZip: agent.officeZip,
        photoUrl: agent.photoUrl,
        head: agent.head,
        body: agent.body,
        allowModifyBio: agent.allowModifyBio,
        hasOwnSite: agent.hasOwnSite,
        redirectUrl: agent.redirectUrl,
        enableAgentLink: agent.enableAgentLink,
        showOnSignupDropDown: agent.showOnSignupDropDown,
        emailHeader: agent.emailHeader,
        sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
        allowModifyListings: agent.allowModifyListings,
        allowModifySubscribers: agent.allowModifySubscribers,
        languageIds: agent.languageIds,
        admin: agent.admin,
		ownsLeads: agent.ownsLeads,
		sortOrder: agent.sortOrder
    };
	return request<AgentResource, NewAgentResource>({
		url: urls.agents(),
		method: "POST",
		body,
	});
};

export const updateAgent = (agent: Agent) => {
	const body: AgentResource = {
        id: agent.id,
        firstName: agent.firstName,
        lastName: agent.lastName,
        email: agent.email,
        officeId: agent.office && agent.office.id,
        agentPageUrl: agent.agentPageUrl,
        lastLoginDate:
            agent.lastLoginDate &&
            agent.lastLoginDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        designation: agent.designation,
        username: agent.username,
        password: agent.password,
        homePhone: agent.homePhone,
        officePhone: agent.officePhone,
        homeFax: agent.homeFax,
        officeFax: agent.officeFax,
        cellPhone: agent.cellPhone,
        staffTypeId: agent.staffType && agent.staffType.id,
        code: agent.code,
        officeAddress: agent.officeAddress,
        officeCity: agent.officeCity,
        officeState: agent.officeState,
        officeZip: agent.officeZip,
        photoUrl: agent.photoUrl,
        head: agent.head,
        body: agent.body,
        allowModifyBio: agent.allowModifyBio,
        hasOwnSite: agent.hasOwnSite,
        redirectUrl: agent.redirectUrl,
        enableAgentLink: agent.enableAgentLink,
        showOnSignupDropDown: agent.showOnSignupDropDown,
        emailHeader: agent.emailHeader,
        sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
        allowModifyListings: agent.allowModifyListings,
        allowModifySubscribers: agent.allowModifySubscribers,
        languageIds: agent.languageIds,
        admin: agent.admin,
		ownsLeads: agent.ownsLeads,
		sortOrder: agent.sortOrder,
		showImageOnPage: agent.showImageOnPage,
    };
	return request<AgentResource, AgentResource>({
		url: urls.agent(agent.id),
		method: "PUT",
		body,
	});
};

export const updateAgents = (agents: Agent[]) => { 
	const body: AgentResource[] = [];
	agents.forEach((agent) => {
		body.push({
			id: agent.id,
			firstName: agent.firstName,
			lastName: agent.lastName,
			email: agent.email,
			officeId: agent.office && agent.office.id,
			agentPageUrl: agent.agentPageUrl,
			lastLoginDate:
				agent.lastLoginDate &&
				agent.lastLoginDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
			designation: agent.designation,
			username: agent.username,
			password: agent.password,
			homePhone: agent.homePhone,
			officePhone: agent.officePhone,
			homeFax: agent.homeFax,
			officeFax: agent.officeFax,
			cellPhone: agent.cellPhone,
			staffTypeId: agent.staffType && agent.staffType.id,
			code: agent.code,
			officeAddress: agent.officeAddress,
			officeCity: agent.officeCity,
			officeState: agent.officeState,
			officeZip: agent.officeZip,
			photoUrl: agent.photoUrl,
			head: agent.head,
			body: agent.body,
			allowModifyBio: agent.allowModifyBio,
			hasOwnSite: agent.hasOwnSite,
			redirectUrl: agent.redirectUrl,
			enableAgentLink: agent.enableAgentLink,
			showOnSignupDropDown: agent.showOnSignupDropDown,
			emailHeader: agent.emailHeader,
			sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
			allowModifyListings: agent.allowModifyListings,
			allowModifySubscribers: agent.allowModifySubscribers,
			languageIds: agent.languageIds,
			admin: agent.admin,
			ownsLeads: agent.ownsLeads,
		});
	})
	return request<AgentResource[], AgentResource[]>({
		url: urls.agents(),
		method: "PUT",
		body,
	});
} 

export const deleteAgent = (agent: Agent) => {
    return requestLegacy<AgentResource>({
        url: urls.agent(agent.id || 0),
        method: "DELETE",
    })
}

export const getBoards = () => {
    return requestLegacy<BoardResource[]>({
        url: urls.boards(),
        method: "GET",
    });
};

export const getAgentBoards = () => {
    return request<AgentBoardResource[]>({
        url: urls.agentBoards(),
        method: "GET",
    })
}

export const createAgentBoard = (agentBoard: NewAgentBoard) => {
    const body: NewAgentBoardResource = {
        agentCode: agentBoard.agentCode,
        agentId: agentBoard.agentId,
        boardId: agentBoard.boardId,
    };
    return request<AgentBoardResource, NewAgentBoardResource>({
        url: urls.agentBoards(),
        method: "POST",
        body,
    });
};

export const updateAgentBoard = (agentBoard: AgentBoard) => {
    const body: AgentBoardResource = {
        id: agentBoard.id,
        agentCode: agentBoard.agentCode,
        agentId: agentBoard.agentId,
        boardId: agentBoard.boardId,
    };
    return request<AgentBoardResource, AgentBoardResource>({
        url: urls.agentBoard(agentBoard.id),
        method: "PUT",
        body,
    });
};

// export const saveAgentBoard = (agentBoard: AgentBoard) => {
//  const body: AgentBoardResource = {
//      id: agentBoard.id,
//      agentCode: agentBoard.agentCode,
//      agentId: agentBoard.agentId,
//      boardId: agentBoard.boardId,
//  }
//  if (agentBoard.id) {
//      return requestLegacy<AgentBoardResource>({
//          url: urls.agentBoard(agentBoard.id),
//          method: "PUT",
//          body,
//      });
//  } else {
//      return requestLegacy<AgentBoardResource>({
//          url: urls.agentBoards(),
//          method: "POST",
//          body,
//      })
//  }
// }

export const deleteAgentBoard = (agentBoard: AgentBoard) => {
    return requestLegacy<AgentBoardResource>({
        url: urls.agentBoard(agentBoard.id || 0),
        method: "DELETE",
    })
}

export const getOffices = () => {
    return requestLegacy<OfficeResource[]>({
        url: urls.offices(),
        method: "GET",
    });
}

export const createOffice = (office: NewOffice) => {
	const body: NewOfficeResource = {
		name: office.name,
		code: office.code,
		priority: office.priority,
		address: office.address,
		city: office.city,
		state: office.state,
		zip: office.zip,
		phone: office.phone,
		fax: office.fax,
		email: office.email,
		photoUrl: office.photoUrl,
		header: office.header,
		description: office.description,
	};
	return request<OfficeResource, NewOfficeResource>({
		url: urls.offices(),
		method: "POST",
		body,
	});
};

export const updateOffice = (office: Office) => {
	const body: OfficeResource = {
		id: office.id,
        name: office.name,
        code: office.code,
        priority: office.priority,
        address: office.address,
        city: office.city,
        state: office.state,
        zip: office.zip,
        phone: office.phone,
        fax: office.fax,
        email: office.email,
        photoUrl: office.photoUrl,
        header: office.header,
        description: office.description,
	};
	return request<OfficeResource, OfficeResource>({
		url: urls.office(office.id),
		method: "PUT",
		body,
	});
};

export const deleteOffice = (office: Office) => {
    return requestLegacy<OfficeResource>({
        url: urls.office(office.id || 0),
        method: "DELETE",
    })
}

export const getOfficeBoards = () => {
    return requestLegacy<OfficeBoardResource[]>({
        url: urls.officeBoards(),
        method: "GET",
    })
}

export const createOfficeBoard = (officeBoard: NewOfficeBoard) => {
	const body: NewOfficeBoardResource = {
		boardId: officeBoard.boardId,
		officeCode: officeBoard.officeCode,
		officeId: officeBoard.officeId,
	}
	return request<OfficeBoardResource, NewOfficeBoardResource>({
		url: urls.officeBoards(),
		method: "POST",
		body,
	})
};

export const updateOfficeBoard = (officeBoard: OfficeBoard) => {
	const body: OfficeBoardResource = {
		id: officeBoard.id,
		boardId: officeBoard.boardId,
		officeCode: officeBoard.officeCode,
		officeId: officeBoard.officeId,
	}
	return request<OfficeBoardResource, OfficeBoardResource>({
		url: urls.officeBoard(officeBoard.id),
		method: "PUT",
		body,
	})
};

export const deleteOfficeBoard = (officeBoard: OfficeBoard) => {
    return requestLegacy<OfficeBoardResource>({
        url: urls.officeBoard(officeBoard.id || 0),
        method: "DELETE",
    })
}

export const getCities = () => {
    return requestLegacy<CityResource[]>({
        url: urls.cities(),
        method: "GET",
    });
}

export const getNeighborhoods = () => {
    return requestLegacy<NeighborhoodResource[]>({
        url: urls.neighborhoods(),
        method: "GET",
    });
}

export const getAggregateStats = () => {
    return requestLegacy<AggregateStatsResource[]>({
        url: urls.aggregateStats(),
        method: "GET",
    });
}

export const getIntegrations = () => {
    return requestLegacy<IntegrationResource[]>({
        url: urls.integrations(),
        method: "GET",
    });
}

export const saveIntegration = (integration: IntegrationPartial) => {
    const body: IntegrationResource = {
        id: integration.id,
        integrationTypeId: integration.integrationType.id,
        name: integration.name,
        data: integration.data,
    };
    if (integration.id) {
        return requestLegacy<IntegrationResource>({
            url: urls.integration(integration.id),
            method: "PUT",
            body,
        });
    } else {
        return requestLegacy<IntegrationResource>({
            url: urls.integrations(),
            method: "POST",
            body
        });
    }
}

export const getIntegrationCalendars = (integrationId: number) => {
    return requestLegacy<IntegrationCalendarResource[]>({
        url: urls.integrationCalendars(integrationId),
        method: "GET",
    });
}

export const deleteIntegration = (integration: IntegrationPartial) => {
    return requestLegacy<IntegrationResource>({
        url: urls.integration(integration.id || 0),
        method: "DELETE",
    });
}

export const testIntegration = (integration: IntegrationPartial) => {
    return requestLegacy<IntegrationResource>({
        url: urls.integrationTest(integration.id || 0),
        method: "POST",
    });
}

export const getSiteLinks = () => {
    return requestLegacy<SiteLinkResource[]>({
        url: urls.siteLinks(),
        method: "GET"
    });
}

export const sendEmail = (label: string, email: string, subject: string, body: string) => {
    return requestLegacy({
        url: urls.sendEmail(email, label, subject, body),
        method: "POST",
    });
}

export const getUploadSignature = (uploadPreset: string) => {
    return requestLegacy<string>({
        url: urls.uploadSignature(uploadPreset),
        method: "GET",
    })
}

export const getGeocode = (address?: string) => {
    return requestLegacy<[ number, number ]>({
        url: urls.geocode(address),
        method: "GET",
    })
}

export const getPages = () => {
    return requestLegacy<PageResource[]>({
        url: urls.pages(),
        method: "GET",
    });
}

export const savePage = (page: PagePartial) => {
    const body: PageResource = {
        id: page.id,
        typeId: page.type.id,
        path: page.path,
        title: page.title,
        head: page.head,
        enabled: page.enabled,
    };
    return requestLegacy<PageResource>({
        url: urls.page(page.id),
        method: "PUT",
        body,
    });
}

export const getLanguages = () => {
    return requestLegacy<LanguageResource[]>({
        url: urls.languages(),
        method: "GET",
    });
}

interface requestLegacyOptions<T> {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    body?: T;
}

const requestLegacy = <T>(options: requestLegacyOptions<T>): Promise<T> => {
    return request(options);
}

const request = <ResponseBody, RequestBody = undefined>(options: requestLegacyOptions<RequestBody>): Promise<ResponseBody> => {
    const { url, method, body } = options;
    return new Promise((resolve, reject) => {
        const creds = credentials.get();
        let auth = ":";
        if (creds) {
            let { username, password, clientId } = creds;
            if (clientId) {
                auth = username + "|" + clientId + ":" + password;
            } else {
                auth = username + ":" + password;
            }
        }
        fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "Authorization": "Basic " + btoa(auth),
                "Content-Type": "application/json",
            },
        }).then(
            async (response) => {
                try {
                    const json = await response.json();
                    if (response.ok) {
                        resolve(json);
                    } else {
                        const error = new Error(json.message);
                        window.newrelic.noticeError(error);
                        reject(error);
                    }
                } catch(error) {
                    window.newrelic.noticeError(error as Error);
                    reject(error);
                }
            },
            (error) => {
                window.newrelic.noticeError(error);
                reject(error);
            }
        );
    });
}