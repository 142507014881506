type TimerCallback = (time: number) => void;

const createTimer = () => {
	let timer: NodeJS.Timeout | null = null;
	let listeners: TimerCallback[] = [];
	let time: number = 0;
	const events: string[] = [
		"load",
		"mousemove",
		"mousedown",
		"touchstart",
		"click",
		"keydown",
		"scroll",
	];

	const start = () => {
		stop();
		timer = setInterval(() => {
			time++;
			notifyListeners(time);
		}, 1000);
	};

	const stop = () => {
		if (timer) {
			clearInterval(timer);
			timer = null;
			listeners = [];
			time = 0;
		}
	};

	const reset = () => {
		time = 0;
	};

	const get = () => {
		return time;
	}

	const registerListener = (callback: TimerCallback) => {
		listeners.push(callback);
	};

	const unregisterListener = (callback: TimerCallback) => {
		listeners = listeners.filter((listener) => listener !== callback);
	};

	const notifyListeners = (time: number) => {
		listeners.forEach((listener) => listener(time));
	};

	const activityListener = (callback: () => void) => {
		events.forEach((event) => {
			window.addEventListener(event, callback);
		});
	};

	start();

	return {
		start,
		stop,
		reset,
		get,
		registerListener,
		unregisterListener,
		activityListener,
	};
};

export default createTimer;
