import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill-overrides.scss";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	onChange: (value: string) => void;
	toolbarId: string;
	value: string;
}
interface State {
	htmlText: string;
	htmlCode: string,
	showSource: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showSource: false,
			htmlText: "",
			htmlCode: ""
		};
		this.quillRef = React.createRef<ReactQuill>();
	}

	quillRef: React.RefObject<ReactQuill>;

	toggleShowSource = () => {
		const { htmlCode, showSource } = this.state;
		if (showSource) {
			this.props.onChange(htmlCode);
			this.setState({
				showSource: false,
			});
		} else {
			if (
				this.quillRef.current &&
				this.quillRef.current.getEditor() &&
				this.quillRef.current.getEditor().root
			) {
				const editorRoot = this.quillRef.current.getEditor().root
				
				this.setState({
					htmlText: editorRoot.innerText,
					htmlCode: editorRoot.innerHTML
				});
			}
			this.setState({
				showSource: true,
			});
		}
	};

	renderToolbar = () => {
		return (
			<div id={this.props.toolbarId}>
				<select className="ql-size">
					<option value="small"></option>
					<option defaultValue="normal"></option>
					<option value="large"></option>
				</select>
				<button className="ql-bold"></button>
				<button className="ql-italic"></button>
				<button className="ql-underline"></button>
				<select className="ql-align">
					<option defaultValue="left"></option>
					<option value="center"></option>
					<option value="right"></option>
					<option value="justify"></option>
				</select>
				<button className="ql-list" value="ordered"></button>
				<button className="ql-list" value="bullet"></button>
				<select className="ql-color">{colorOptions.map((option) => option)}</select>
				<select className="ql-background">
					{colorOptions.map((option) => option)}
				</select>
				<button className="ql-toggleShowSource" onClick={this.toggleShowSource}>
					<Icons.Code />
				</button>
				<button className="ql-link"></button>
				<button className="ql-image"></button>
				<button className="ql-clean"></button>
			</div>
		);
	};

	render() {
		const { classes, onChange, toolbarId, value } = this.props;
		const { htmlText, showSource } = this.state;
		return (
			<>
				{this.renderToolbar()}
				{showSource && (
					<textarea
						className={classes.textarea}
						onChange={(event) => this.setState({ htmlText: event.target.value })}
						value={htmlText}
					/>
				)}
				{
					<ReactQuill
						modules={{
							toolbar: {
								container: `#${toolbarId}`,
							},
						}}
						onChange={onChange}
						ref={this.quillRef}
						style={{
							display: showSource ? "none" : "block",
							wordBreak: "break-word",
						}}
						value={value}
					/>
				}
			</>
		);
	}
}

export const RichTextEditor = Mui.withStyles(styles)(Component);

const colorOptions = [
	<option key="placeholder" defaultValue="#000000"></option>,
	<option key="#e60000" value="#e60000"></option>,
	<option key="#ff9900" value="#ff9900"></option>,
	<option key="#ffff00" value="#ffff00"></option>,
	<option key="#008a00" value="#008a00"></option>,
	<option key="#0066cc" value="#0066cc"></option>,
	<option key="#9933ff" value="#9933ff"></option>,
	<option key="#ffffff" value="#ffffff"></option>,
	<option key="#facccc" value="#facccc"></option>,
	<option key="#ffebcc" value="#ffebcc"></option>,
	<option key="#ffffcc" value="#ffffcc"></option>,
	<option key="#cce8cc" value="#cce8cc"></option>,
	<option key="#cce0f5" value="#cce0f5"></option>,
	<option key="#ebd6ff" value="#ebd6ff"></option>,
	<option key="#bbbbbb" value="#bbbbbb"></option>,
	<option key="#f06666" value="#f06666"></option>,
	<option key="#ffc266" value="#ffc266"></option>,
	<option key="#ffff66" value="#ffff66"></option>,
	<option key="#66b966" value="#66b966"></option>,
	<option key="#66a3e0" value="#66a3e0"></option>,
	<option key="#c285ff" value="#c285ff"></option>,
	<option key="#888888" value="#888888"></option>,
	<option key="#a10000" value="#a10000"></option>,
	<option key="#b26b00" value="#b26b00"></option>,
	<option key="#b2b200" value="#b2b200"></option>,
	<option key="#006100" value="#006100"></option>,
	<option key="#0047b2" value="#0047b2"></option>,
	<option key="#6b24b2" value="#6b24b2"></option>,
	<option key="#444444" value="#444444"></option>,
	<option key="#5c0000" value="#5c0000"></option>,
	<option key="#663d00" value="#663d00"></option>,
	<option key="#666600" value="#666600"></option>,
	<option key="#003700" value="#003700"></option>,
	<option key="#002966" value="#002966"></option>,
	<option key="#3d1466" value="#3d1466"></option>,
];
